<template>
  <section>
    <FormulateForm name="formReceive">
      <b-row>
        <b-col>
          <b-card-actions
            :show-loading="busy"
            :title="$t('Dados da Receita')"
            no-actions
          >
            <e-bill-form
              ref="eBillForm"
              :form-data="receiveForm"
              is-receive
            />
          </b-card-actions>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-card-actions
            :show-loading="busy"
            :title="$t('Dados de pagamento')"
            no-actions
          >
            <e-bill-payment-form
              ref="eBillPaymentForm"
              :form-data="receiveForm"
              :set-documents="stSetDocuments"
              :documents="documents"
            />
          </b-card-actions>
        </b-col>
      </b-row>

      <b-row
        v-if="isEdition"
        class="bottom-form-submit"
        style="right: 100px"
      >
        <b-col cols="12">
          <e-button
            variant="primary"
            :text="$t('Receber')"
            @click="payReceive"
          />
        </b-col>
      </b-row>
    </FormulateForm>

    <e-crud-actions
      @save="saveReceive"
      @cancel="cancelReceive"
    />

    <sidebar-paid-off
      ref="sidebarPaidOff"
      is-receive
      :title="$t('Pagar Receita')"
      :on-save="savePaidOff"
    />
  </section>
</template>

<script>
import { BCol, BRow } from 'bootstrap-vue'
import BCardActions from '@/@core/components/b-card-actions/BCardActions.vue'
import EButton from '@/views/components/EButton.vue'
import { mapActions, mapState } from 'vuex'
import { accountTypes, paymentTypes } from '@/mixins'
import EBillForm from '@/views/pages/financial/components/EBillForm.vue'
import EBillPaymentForm from '@/views/pages/financial/components/EBillPaymentForm.vue'
import ECrudActions from '@/views/components/ECrudActions.vue'
import SidebarPaidOff from '../components/AccountPayableInstallmentSidebar.vue'

export default {
  name: 'ExpenseMaintain',
  components: {
    BCardActions,
    BCol,
    BRow,
    EButton,
    EBillForm,
    EBillPaymentForm,
    SidebarPaidOff,
    ECrudActions,
  },

  mixins: [paymentTypes, accountTypes],

  data() {
    return {
      busy: false,
    }
  },

  computed: {
    ...mapState('pages/financial/billsToReceive/receivableMaintain', ['receiveForm', 'documents']),

    isEdition() {
      return !!this.$router.currentRoute.params.id
    },
  },

  methods: {
    ...mapActions('pages/financial/billsToReceive/receivableMaintain', {
      stCleanReceive: 'cleanState',
      stSetDocuments: 'setDocuments',
    }),

    async saveReceive() {
      if (!this.$refs.eBillForm.isValid() || !this.$refs.eBillPaymentForm.isValid()) {
        this.showInvalidDataMessage({ message: '' })
        return
      }

      try {
        this.busy = true
        await this.uploadDocuments()
        console.log('save receive', this.receiveForm, this.documents)
        this.showSuccess({ message: 'Despesa adicionada' })
      } catch (error) {
        this.showError({ error, message: this.$t('Não foi possível concluir a operação') })
      } finally {
        this.busy = false
      }
    },

    cancelReceive() {
      this.stCleanReceive()
      this.$router.back()
    },

    async uploadDocuments() {
      console.log('documents upload to return url', this.documents)
      return []
    },

    payReceive() {
      this.$refs.sidebarPaidOff.onShowSidebar(this.receiveForm)
    },

    async savePaidOff(form) {
      console.log(form)
      await new Promise(resolve => setTimeout(() => resolve(), 1000))
    },
  },
}
</script>

<style></style>

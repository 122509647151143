var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "FormulateForm",
        { attrs: { name: "formReceive" } },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-card-actions",
                    {
                      attrs: {
                        "show-loading": _vm.busy,
                        title: _vm.$t("Dados da Receita"),
                        "no-actions": "",
                      },
                    },
                    [
                      _c("e-bill-form", {
                        ref: "eBillForm",
                        attrs: {
                          "form-data": _vm.receiveForm,
                          "is-receive": "",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-card-actions",
                    {
                      attrs: {
                        "show-loading": _vm.busy,
                        title: _vm.$t("Dados de pagamento"),
                        "no-actions": "",
                      },
                    },
                    [
                      _c("e-bill-payment-form", {
                        ref: "eBillPaymentForm",
                        attrs: {
                          "form-data": _vm.receiveForm,
                          "set-documents": _vm.stSetDocuments,
                          documents: _vm.documents,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.isEdition
            ? _c(
                "b-row",
                {
                  staticClass: "bottom-form-submit",
                  staticStyle: { right: "100px" },
                },
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("e-button", {
                        attrs: { variant: "primary", text: _vm.$t("Receber") },
                        on: { click: _vm.payReceive },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c("e-crud-actions", {
        on: { save: _vm.saveReceive, cancel: _vm.cancelReceive },
      }),
      _c("sidebar-paid-off", {
        ref: "sidebarPaidOff",
        attrs: {
          "is-receive": "",
          title: _vm.$t("Pagar Receita"),
          "on-save": _vm.savePaidOff,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }